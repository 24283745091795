var base = "api-bxt-order/bxt-order"
let API = {
    queryUsers: base + "/queryUsers",
    submitOrder: base + "/submitOrder",
    orderNums: base + "/common/orderNums",
    orderType: base + "/common/orderType",
    orderList: base + "/orderList",
    scoll: base + "/common/scoll",
    handle: base + "/handle",
    sToken: base + "/sToken",
    evaluate: base + "/evaluate",
    orderDetail: base + "/orderDetail/",
    cancelTypeList: base + "/common/cancelTypeList",
    publishJob: base + "/publishJob",
    createWorkerCard: base + "/createWorkerCard",
    jobList: base + "/jobList",
    jobDetail: base + "/jobDetail/",
    workerList: base + "/workerList",
    workerDetail: base + "/workerDetail/",
}
export default API