<template>
  <div class="service-center app_content">
    <header-ele></header-ele>
    <div class="warp_body">

      <div class="grid">
        <van-nav-bar v-show="showBackButton"
                     left-text="返回"
                     left-arrow
                     border="false"
                     @click-left="onClickLeft"
        />
        <van-grid :column-num="3" clickable>
          <van-grid-item v-for="item in gridList" @click="go(item)"
                         :key="item" :icon="item.icon" :text="item.name"/>
        </van-grid>
      </div>


      <div>
        <!--        <div class="">-->
        <!--          <van-divider>服务详情</van-divider>-->
        <!--        </div>-->

        <div class="scolled">
          <!--        <div>-->
          <!--          <van-icon name="volume-o"></van-icon>-->
          <!--        </div>-->
          <div v-for="list in  scollList" v-bind:key="list">
            <van-notice-bar left-icon="volume-o" background="#FFD5CC" color="#FA421C"
                            scrollable="false" v-if="list.length>0">
              <van-swipe
                  vertical
                  class="notice-swipe"
                  :autoplay="3000"
                  :show-indicators="false"
              >
                <div v-for="item in list" v-bind:key="item">
                  <van-swipe-item>{{ item }}</van-swipe-item>
                </div>
              </van-swipe>
            </van-notice-bar>
            <!--            <van-notice-bar left-icon="volume-o" background="white" color="red"-->
            <!--                            :scrollable="false" v-if="orderList2.length>0">-->
            <!--              <van-swipe-->
            <!--                  vertical-->
            <!--                  class="notice-swipe"-->
            <!--                  :autoplay="3000"-->
            <!--                  :show-indicators="false"-->
            <!--              >-->
            <!--                <div v-for="item in orderList2">-->
            <!--                  <van-swipe-item>{{ item }}</van-swipe-item>-->
            <!--                </div>-->
            <!--              </van-swipe>-->
            <!--            </van-notice-bar>-->
            <!--            <van-notice-bar left-icon="volume-o" background="white" color="red"-->
            <!--                            :scrollable="false" v-if="orderList3.length>0">-->
            <!--              <van-swipe-->
            <!--                  vertical-->
            <!--                  class="notice-swipe"-->
            <!--                  :autoplay="3000"-->
            <!--                  :show-indicators="false"-->
            <!--              >-->
            <!--                <div v-for="item in orderList3">-->
            <!--                  <van-swipe-item>{{ item }}</van-swipe-item>-->
            <!--                </div>-->
            <!--              </van-swipe>-->
            <!--            </van-notice-bar>-->
            <!--            <van-notice-bar left-icon="volume-o" background="white" color="red"-->
            <!--                            :scrollable="false" v-if="orderList4.length>0">-->
            <!--              <van-swipe-->
            <!--                  vertical-->
            <!--                  class="notice-swipe"-->
            <!--                  :autoplay="3000"-->
            <!--                  :show-indicators="false"-->
            <!--              >-->
            <!--                <div v-for="item in orderList4">-->
            <!--                  <van-swipe-item>{{ item }}</van-swipe-item>-->
            <!--                </div>-->
            <!--              </van-swipe>-->
            <!--            </van-notice-bar>-->
          </div>

          <van-search
              v-model="keyword"
              show-action
              label="成都"
              placeholder="搜索你想要的工作"
              @search="onSearch"
              shape="round"
              background="#4DBAFD"
              @click-input="onSearch"
              @click-left-icon="onSearch"
          >
            <template #action>
              <div @click="onClickButton" style="color:#FFFFFF;">搜索</div>
            </template>
          </van-search>

        </div>


        <van-tabs v-model:active="active" @click-tab="onClickTab" shrink="true" color="#4DBAFD">
          <van-tab title="招工">

            <!--              <van-pull-refresh v-model="more.refreshing" @refresh="onRefresh" >-->

            <van-list
                v-model:loading="more.loading"
                :finished="more.finished"
                :finished-text="more.finishText"
                @load="onLoad"
                :offset="1"
            >
              <div style="height: 100%;
    overflow: scroll;">
                <div v-for="item in jobList" :key="item" style="padding: 7px;">
                  <JobCard :job="item"></JobCard>
                </div>
              </div>
            </van-list>
            <!--              </van-pull-refresh>-->


          </van-tab>
          <van-tab title="找活">
            <van-list
                v-model:loading="moreWorker.loading"
                :finished="moreWorker.finished"
                :finished-text="moreWorker.finishText"
                @load="onLoadWorkerList"
                :offset="1"
            >
              <div style="height: 100%;
    overflow: scroll;">
                <div v-for="item in workerList" :key="item" style="padding: 7px;">
                  <PersonInfoCard :worker="item"></PersonInfoCard>
                </div>
              </div>
            </van-list>
          </van-tab>
        </van-tabs>
        <div style=" position: fixed;bottom: 100px;left: 50%;transform: translate(-50%, -50%);">
          <van-button v-show="showPublishWorkBtn" icon="contact" color="#4DBAFD" size="small" to="PublishJob">发布招工
          </van-button>
          <van-button v-show="showPersonInfoBtn" icon="contact" color="#4DBAFD" size="small"
                      @click="publishPersonInfo">
            发布找活
          </van-button>
        </div>

        <footer-ele></footer-ele>
      </div>
    </div>
  </div>
</template>

<script>

import {onBeforeMount, reactive, toRefs} from 'vue'
import req from "../request/request"
import API from "../api/User"
import API_ORDER from "../api/Order"
import HeaderEle from "../components/common/headerEle";
import FooterEle from "../components/common/footerEle";
import router from "../router";
import JobCard from "@/components/common/JobCard";
import utils from "@/util/utils";
import {Dialog, Toast} from "vant";
import PersonInfoCard from "@/components/common/PersonInfoCard";


export default {
  name: 'Register',
  components: {PersonInfoCard, JobCard, HeaderEle, FooterEle},

  setup() {
    onBeforeMount(() => {
      methodsMap.getServiceList()
      methodsMap.getScoll()
      // data.areaList =
      utils.getAreaList().then(
          (d) => {
            d.forEach((dd) => {
              data.areaList.push(dd)
            })
          });

      let user = localStorage.getItem("userinfo")
      let userJson = user ? JSON.parse(user) : {}
      data.showPublishWorkBtn = user ? (userJson.userType.toUpperCase() == 'C' ? true : false) : false;
      data.showPersonInfoBtn = user ? (userJson.userType.toUpperCase() == 'P' ? true : false) : false;
      // methodsMap.getjobList(data.queryResult.page, data.queryResult.pageSize)
    });
    const data = reactive({
          showBackButton: false,
          areaList: [],
          gridList: [],
          scollList: [],
          activeName: '',
          jobList: [],
          workerList: [],
          more: {loading: false, finished: false, refreshing: false, finishText: '下拉查看更多'},
          moreWorker: {loading: false, finished: false, refreshing: false, finishText: '下拉查看更多'},
          pageInfo: {
            pageSize: 5,
            page: 1,
          },
          pageInfoWorker: {
            pageSize: 5,
            page: 1,
          },
          isMore: true,
          keyword: '',
          active: 0,
          showPublishWorkBtn: false,
          showPersonInfoBtn: false,

        },
    )

    const methodsMap = {
      onSearch: () => {
        router.push("/Search")
      }, onClickTab: () => {
        console.log(data.active)
      },
      onLoad: () => {
        methodsMap.getjobList(data.pageInfo.page, data.pageInfo.pageSize);
        data.pageInfo.page++;
      },
      onLoadWorkerList: () => {
        console.log("-------data.more------------------", data.moreWorker)
        methodsMap.getWorkerCardList(data.pageInfoWorker.page, data.pageInfoWorker.pageSize);
        data.pageInfoWorker.page++;
        console.log(data.workerList)
      },
      onRefresh: () => {
        alert("onRefresh")
        data.loading = true;
        data.pageInfo.page++;
        methodsMap.onLoad();
      },
      onClickButton: () => {
        methodsMap.onSearch()
      },
      onClickLeft: () => {
        methodsMap.getServiceList()
      },
      publishPersonInfo: () => {
        req.request(API.getDetailUserInfo, null, {method: 'GET'}).then(
            (res) => {
              let info = res.data.data
              if(info.price){
                router.push("PublishPersonInfo")
              }else {
                Dialog.confirm({
                  title: '提示',
                  message: '请先前往完善个人工种信息后',
                }).then(() => {
                  router.push("CompleteWorker")
                }).catch(() => {
                  // on cancel
                });
              }
            }
        )
      },
      getServiceList: () => {
        data.showBackButton=false
        req.request(API.workTypeList, null, {method: 'GET'}).then((res) => {
          let d = res.data.data
          data.gridList = d
        })
      },
      go: (dd) => {
        let user = localStorage.getItem("userinfo") ? JSON.parse(localStorage.getItem("userinfo")) : ''
        let childrenList = dd.childrenList;
        console.log()
        if (childrenList.length > 0) {
          data.showBackButton=true
          data.gridList = childrenList
        } else {
          if (!user) {
            dd.route = "RegisterSelect"
          } else {
            if (user.userType.toLowerCase() == 'p') dd.route = ''
            else dd.route = 'ChooseWorker'
          }
          sessionStorage.setItem("wokerType", JSON.stringify(dd))
          if (dd.route) router.push(dd.route)
          else Toast("无权限！")
        }


      },
      getScoll: () => {
        req.request(API_ORDER.scoll, null, {method: 'GET'}).then((res) => {
          data.scollList = res.data.data
        })
      },
      getjobList: (page, pageSize) => {
        // data.more = {loading: false, finished: false, refreshing: true}
        req.request(API_ORDER.jobList, {page: page, pageSize: pageSize}, {method: 'GET'}).then((res) => {
          let list = res.data.data.records
          console.log("list", list)
          data.more.loading = false;
          list.forEach(
              (d) => {
                utils.getArea(d.companyArea).then(
                    v => {
                      d.companyArea = v;
                      data.jobList.push(d)
                    }
                )
              }
          )
          if (res.data.data.pages <= res.data.data.current) {
            data.more.finished = true;
            data.more.finishText = "没有更多了";
            // data.more = {loading: true, finished: true, refreshing: false, finishText: '没有更多了'}
          } else {
            // data.more = {loading: false, finished: false, refreshing: false, finishText: '下拉查看更多'}
            data.more.finished = false;
          }
        })
      },
      getWorkerCardList: (page, pageSize) => {
        req.request(API_ORDER.workerList, {page: page, pageSize: pageSize}, {method: 'GET'}).then((res) => {
          let list = res.data.data.records
          data.moreWorker.loading = false;
          list.forEach(
              (d) => {
                data.areaList.forEach((dd) => {
                  if (dd.value == d.area) {
                    d.area = dd.text
                  }
                })
                data.workerList.push(d)

              }
          )
          if (res.data.data.pages <= res.data.data.current) {
            data.moreWorker.finished = true;
            data.moreWorker.finishText = "没有更多了";
            // data.more = {loading: true, finished: true, refreshing: false, finishText: '没有更多了'}
          } else {
            // data.more = {loading: false, finished: false, refreshing: false, finishText: '下拉查看更多'}
            data.moreWorker.finished = false;
          }
        })
      }
    }


    return {
      ...toRefs(data),
      ...methodsMap,
    };
  }
}
</script>
<style lang="less">

.app_content {
  --van-list-text-line-height: 200px;

  .grid {
    --van-nav-bar-height:30px;
    --van-nav-bar-text-color: #c5c3c3;
    --van-nav-bar-icon-color: #c5c3c3;
    margin-top: 5px;
    //--van-grid-item-content-background-color: #7eb0e3;
    //--van-grid-item-text-color: white;
    //--van-grid-item-content-active-color: #489aee;
    font-weight: 900;
    background: #FFFFFF;
  }

  .notice-swipe {
    height: 40px;
    line-height: 40px;
  }

  .scolled {
    //display: flex;
    overflow: scroll;

  }
}
</style>
