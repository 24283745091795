import req from "@/request/request";
import API from "@/api/User";

function getArea(val) {
    return new Promise((resolve) => {
        req.request(API.areaList, null, {method: 'GET'})
            .then((res) => {
                let d = res.data.data
                for (let i = 0; i < d.length; i++) {
                    if (d[i].value == val) {
                        resolve(d[i].text)
                    }
                }
            })

    })
}

function getAreaList() {
    return new Promise((resolve) => {
        req.request(API.areaList, null, {method: 'GET'})
            .then((res) => {
                return resolve(res.data.data)
            })

    })
}


let UTILS = {
    getArea,getAreaList
}


export default UTILS;