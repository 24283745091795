<template>

  <div class="personItem" @click="queryJob(worker.id)">
    <div class="personItemImg">
      <van-image round
                 width="42"
                 height="42"
                 :src="worker.img"
      />
    </div>
    <div class="personItemInfoDetail">
      <span style="font-size: 20px">{{ worker.name }}</span>
      <span>{{ worker.gender == 2 ? '女' : '男' }}</span>
      <span>{{ worker.workingAge ? '工作' + worker.workingAge + '年' : '' }}</span>
    </div>

    <div class="personItemContact">
      <van-button size="small" color="#4DBAFD" round @click.stop="contact(worker)">联系工人</van-button>
    </div>
    <div class="personItemWorkType">
      {{ worker.workerType }}
    </div>
    <div class="divider"></div>
    <div class="personItemArea">
      <van-icon name="location-o" color="orange"/>
      {{ worker.area }}
    </div>
  </div>

</template>

<script>

import {onBeforeMount, reactive, toRefs} from 'vue'
import router from "../../router";
import {useRoute} from "vue-router";
import API_ORDER from "@/api/Order";
import req from "@/request/request";
import API from "@/api/User";
import {Toast} from "vant";
import API_USER from "@/api/UserReq";


export default {
  name: 'PersonInfoCard',
  props: {
    worker: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  setup() {
    const data = reactive({},
    )
    const methodsMap = {

      contact: (obj) => {
        let reqJson = {
          "companyUid": JSON.parse(localStorage.getItem("userinfo")).uid,
          "workerUid": obj.uid,
        }
        console.log(reqJson)
        API_USER.getSecretNo(reqJson)
      },
      queryJob: (val) => {
        Toast("正在加载...")
        // window.location.href = "/JobDetail/" + val + "?t=" + new Date().getTime()
        router.push("/PublishedPersonInfoDetail/" + val + "?t=" + new Date().getTime())
      }
    }


    return {
      ...toRefs(data),
      ...methodsMap,
    };
  }
}
</script>
<style lang="less" scoped>
.personItem {
  background-color: #FFFFFF;
  border-radius: 12px;
  padding: 10px;

  &InfoDetail {
    position: relative;
    top: -40px;
    left: 50px;

    span {
      padding-left: 10px;
    }

  }

  &Img {
    height: 100%;
    width: 20%;
  }

  &WorkType {
    color: blue;
    position: absolute;
    top: 90px;
    left: 100px;
  }

  .divider {
    border: 0.5px dashed #afa9a9;
    margin: 5px 0;

  }

  &Area {
    font-size: 12px;
    color: gray;
  }

  &Contact {
    position: absolute;
    top: 83px;
    right: 30px;
    z-index: 100;
  }

}
</style>
