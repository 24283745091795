<template>
  <div class="footer">
    <van-tabbar v-model="footerActive" :route="true" active-color="rgb(77,186,253)" inactive-color="rgb(201,201,201)">
      <van-tabbar-item v-for=" item in  footList" v-bind:key="item" :name="item.code"
                       replace :icon="item.icon"  :to="item.route">{{ item.name }}
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import {onMounted, reactive, toRefs} from 'vue'
import req from "../../request/request";
import API from "../../api/User";

export default {
  name: 'footerEle',
  components: {},
  setup() {

    onMounted(() => {
      getFootList()
    })

    let data = reactive({
      footerActive: 'Home',
      footList: [],
    });

    const getFootList = function () {
      req.request(API.footList, null, {method: 'GET'}).then((res) => {
        data.footList = res.data.data
      })
    }

    return {
      ...toRefs(data),
      getFootList
    }
  }
}
</script>