import req from "../request/request";
import API from "./User";
import {Dialog,Toast} from "vant";

function getSecretNo(reqJson) {
    let userinfo = JSON.parse(localStorage.getItem("userinfo"))
    let phone = userinfo.phone.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2');
    Dialog.alert({
        title: '重要提示',
        message: '请确认是由手机号码' + phone + '进行拨打，否则无法拨打成功!',
    }).then(() => {
        req.request(API.getSecretNo, reqJson, {method: 'POST'},)
            .then((res) => {
                if (res.data.code == 200) {
                    window.location.href = 'tel:' + res.data.data
                }
            })
    });

}
function editUser(val){
    let reqJson = {
        "orderStatus": val,
    }
    req.request(API.edit, reqJson, {method: 'POST'})
        .then(() => {
            Toast("修改成功！")
        })
}

let USER_REQ = {
    getSecretNo,
    editUser
}

export default USER_REQ