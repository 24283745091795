<template>

  <div class="jobItem" @click="queryJob(job.id)">
    <div class="title">
      {{ job.jobTitle }}
    </div>
    <div class="keyword" v-for="item in job.keyword" :key="item">
      <van-tag color="#4DBAFD" style="margin-left:  5px" plain>{{
          item
        }}
      </van-tag>
    </div>

    <div class="contact">
      <van-button color="#4DBAFD" size="small" round icon="phone-o" @click.stop="contact(job)">联系老板</van-button>
    </div>
    <div class="company">
      {{ job.company ? job.company : '伯训通' }}
    </div>
    <div class="area">
      <div>
        <van-icon name="location-o"/>
        {{ job.companyArea }}
      </div>
      <div>{{ job.createTime }}</div>
    </div>
  </div>

</template>

<script>

import {onBeforeMount, reactive, toRefs} from 'vue'
import router from "../../router";
import {useRoute} from "vue-router";
import API_ORDER from "@/api/Order";
import req from "@/request/request";
import API from "@/api/User";
import {Toast} from "vant";
import API_USER from "@/api/UserReq";


export default {
  name: 'JobCard',
  props: {
    job: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  setup() {
    const data = reactive({},
    )
    const methodsMap = {

      contact: (obj) => {
        let reqJson = {
          "companyUid": JSON.parse(localStorage.getItem("userinfo")).uid,
          "workerUid": obj.uid,
        }
        console.log(reqJson)
        API_USER.getSecretNo(reqJson)
      },
      queryJob: (val) => {
        Toast("正在加载...")
        window.location.href = "/JobDetail/" + val + "?t=" + new Date().getTime()
        // router.push("/JobDetail/" + val+"?t="+ new Date().getTime())
      }
    }


    return {
      ...toRefs(data),
      ...methodsMap,
    };
  }
}
</script>
<style lang="less" scoped>
.jobItem {
  background-color: #FFFFFF;
  border-radius: 12px;
  padding: 10px;

  .title {
    font-size: 18px;
    color: #333333;
    font-weight: 700;
  }

  .keyword {
    color: #777777;
    font-size: 13px;
    display: inline-block;
    background-color: #F7F7F7;
    margin-top: 10px;
  }

  .company {
    display: inline-block;
    color: #DA9A0A;
    font-size: 11px;
    border: 1px solid #DA9A0A;
    border-radius: 3px;
    padding: 2px;
  }

  .area {
    display: flex;
    justify-content: space-between;
    color: #777777;
    font-size: 11px;
  }

  .contact {
    position: relative;
    top: 18px;
    margin-left: 250px;
  }
}
</style>
